import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/container";
import { PlainPageTitle, PlainPageHeader, PlainPageMetadata } from "../components/common";

const TermsOfUse = () => (
  <Layout>
    <SEO
      title="Terms of Use"
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <Container>
      <PlainPageTitle>Website Terms of Use</PlainPageTitle>
      <PlainPageMetadata>
        <p>Version 1.0</p>
        <p>Last revised on: July 4, 2017</p>
      </PlainPageMetadata>
      <p>
        The website located at www.percept.ai (the&nbsp;<b>“Site”</b>) is a
        copyrighted work belonging to Buddy AI Inc. (Percept.AI) (
        <b>“Company”, “us”, “our”,</b>&nbsp;and&nbsp;<b>“we”</b>). Certain
        features of the Site may be subject to additional guidelines, terms, or
        rules, which will be posted on the Site in connection with such
        features. All such additional terms, guidelines, and rules are
        incorporated by reference into these Terms.
      </p>
      <p>
        These Terms of Use (these&nbsp;<b>“Terms”</b>) set forth the legally
        binding terms and conditions that govern your use of the Site. By
        accessing or using the Site, you are accepting these Terms (on behalf of
        yourself or the entity that you represent), and you represent and
        warrant that you have the right, authority, and capacity to enter into
        these Terms (on behalf of yourself or the entity that you represent).
        you may not access or use the Site or accept the Terms if you are not at
        least 18 years old. If you do not agree with all of the provisions of
        these Terms, do not access and/or use the Site.
      </p>
      <p>
        These terms require the use of arbitration (Section 10.2) on an
        individual basis to resolve disputes, rather than jury trials or class
        actions,and also limit the remedies available to you in the event of a
        dispute.
      </p>
      <PlainPageHeader>Accounts</PlainPageHeader>
      <p>
        <b>Account Creation.</b>&nbsp;In order to use certain features of the
        Site, you must register for an account (“Account”) and provide certain
        information about yourself as prompted by the account registration form.
        You represent and warrant that: (a) all required registration
        information you submit is truthful and accurate; (b) you will maintain
        the accuracy of such information. You may delete your Account at any
        time, for any reason, by following the instructions on the Site. Company
        may suspend or terminate your Account in accordance with Section 8.
      </p>
      <p>
        <b>Account Responsibilities.</b>&nbsp;You are responsible for
        maintaining the confidentiality of your Account login information and
        are fully responsible for all activities that occur under your Account.
        You agree to immediately notify Company of any unauthorized use, or
        suspected unauthorized use of your Account or any other breach of
        security. Company cannot and will not be liable for any loss or damage
        arising from your failure to comply with the above requirements.
      </p>
      <PlainPageHeader>Access to the Site</PlainPageHeader>
      <p>
        <b>License.</b>&nbsp;Subject to these Terms, Company grants you a
        non-transferable, non-exclusive, revocable, limited license to use and
        access the Site solely for your own personal, noncommercial use.
      </p>
      <p>
        <b>Certain Restrictions.</b>&nbsp;The rights granted to you in these
        Terms are subject to the following restrictions: (a) you shall not
        license, sell, rent, lease, transfer, assign, distribute, host, or
        otherwise commercially exploit the Site, whether in whole or in part, or
        any content displayed on the Site; (b) you shall not modify, make
        derivative works of, disassemble, reverse compile or reverse engineer
        any part of the Site; (c) you shall not access the Site in order to
        build a similar or competitive website, product, or service; and (d)
        except as expressly stated herein, no part of the Site may be copied,
        reproduced, distributed, republished, downloaded, displayed, posted or
        transmitted in any form or by any means. Unless otherwise indicated, any
        future release, update, or other addition to functionality of the Site
        shall be subject to these Terms. All copyright and other proprietary
        notices on the Site (or on any content displayed on the Site) must be
        retained on all copies thereof.
      </p>
      <p>
        <b>Modification.</b>&nbsp;Company reserves the right, at any time, to
        modify, suspend, or discontinue the Site (in whole or in part) with or
        without notice to you. You agree that Company will not be liable to you
        or to any third party for any modification, suspension, or
        discontinuation of the Site or any part thereof.
      </p>
      <p>
        <b>No Support or Maintenance.</b>&nbsp;You acknowledge and agree that
        Company will have no obligation to provide you with any support or
        maintenance in connection with the Site.
      </p>
      <p>
        <b>Ownership.</b>&nbsp;Excluding any User Content that you may provide
        (defined below), you acknowledge that all the intellectual property
        rights, including copyrights, patents, trade marks, and trade secrets,
        in the Site and its content are owned by Company or Company’s suppliers.
        Neither these Terms (nor your access to the Site) transfers to you or
        any third party any rights, title or interest in or to such intellectual
        property rights, except for the limited access rights expressly set
        forth in Section 2.1. Company and its suppliers reserve all rights not
        granted in these Terms. There are no implied licenses granted under
        these Terms.
      </p>
      <PlainPageHeader>User Content</PlainPageHeader>
      <p>
        <b>User Content.</b>
        <b>“User Content”</b>&nbsp;means any and all information and content
        that a user submits to, or uses with, the Site (e.g., content in the
        user’s profile or postings). You are solely responsible for your User
        Content. You assume all risks associated with use of your User Content,
        including any reliance on its accuracy, completeness or usefulness by
        others, or any disclosure of your User Content that personally
        identifies you or any third party. You hereby represent and warrant that
        your User Content does not violate our Acceptable Use Policy (defined in
        Section 3.3). You may not represent or imply to others that your User
        Content is in any way provided, sponsored or endorsed by Company.
        Because you alone are responsible for your User Content, you may expose
        yourself to liability if, for example, your User Content violates the
        Acceptable Use Policy. Company is not obligated to backup any User
        Content, and your User Content may be deleted at any time without prior
        notice. You are solely responsible for creating and maintaining your own
        backup copies of your User Content if you desire.
      </p>
      <p>
        <b>License.</b>&nbsp; You hereby grant (and you represent and warrant
        that you have the right to grant) to Company an irrevocable,
        nonexclusive, royalty-free and fully paid, worldwide license to
        reproduce, distribute, publicly display and perform, prepare derivative
        works of, incorporate into other works, and otherwise use and exploit
        your User Content, and to grant sublicenses of the foregoing rights,
        solely for the purposes of including your User Content in the Site. You
        hereby irrevocably waive (and agree to cause to be waived) any claims
        and assertions of moral rights or attribution with respect to your User
        Content.
      </p>
      <PlainPageHeader>Acceptable Use Policy.</PlainPageHeader>
      <p>
        The following terms constitute our&nbsp;<b>“Acceptable Use Policy”</b>
      </p>
      <p>
        You agree not to use the Site to collect, upload, transmit, display, or
        distribute any User Content (i) that violates any third-party right,
        including any copyright, trademark, patent, trade secret, moral right,
        privacy right, right of publicity, or any other intellectual property or
        proprietary right; (ii) that is unlawful, harassing, abusive, tortious,
        threatening, harmful, invasive of another’s privacy, vulgar, defamatory,
        false, intentionally misleading, trade libelous, pornographic, obscene,
        patently offensive, promotes racism, bigotry, hatred, or physical harm
        of any kind against any group or individual or is otherwise
        objectionable; (iii) that is harmful to minors in any way; or (iv) that
        is in violation of any law, regulation, or obligations or restrictions
        imposed by any third party.
      </p>
      <p>
        In addition, you agree not to: (i) upload, transmit, or distribute to or
        through the Site any computer viruses, worms, or any software intended
        to damage or alter a computer system or data; (ii) send through the Site
        unsolicited or unauthorized advertising, promotional materials, junk
        mail, spam, chain letters, pyramid schemes, or any other form of
        duplicative or unsolicited messages, whether commercial or otherwise;
        (iii) use the Site to harvest, collect, gather or assemble information
        or data regarding other users, including e-mail addresses, without their
        consent; (iv) interfere with, disrupt, or create an undue burden on
        servers or networks connected to the Site, or violate the regulations,
        policies or procedures of such networks; (v) attempt to gain
        unauthorized access to the Site (or to other computer systems or
        networks connected to or used together with the Site), whether through
        password mining or any other means; (vi) harass or interfere with any
        other user’s use and enjoyment of the Site; or (vi) use software or
        automated agents or scripts to produce multiple accounts on the Site, or
        to generate automated searches, requests, or queries to (or to strip,
        scrape, or mine data from) the Site (provided, however, that we
        conditionally grant to the operators of public search engines revocable
        permission to use spiders to copy materials from the Site for the sole
        purpose of and solely to the extent necessary for creating publicly
        available searchable indices of the materials, but not caches or
        archives of such materials, subject to the parameters set forth in our
        robots.txt file).
      </p>
      <p>
        <b>Enforcement.</b>&nbsp;We reserve the right (but have no obligation)
        to review any User Content, and to investigate and/or take appropriate
        action against you in our sole discretion if you violate the Acceptable
        Use Policy or any other provision of these Terms or otherwise create
        liability for us or any other person. Such action may include removing
        or modifying your User Content, terminating your Account in accordance
        with Section 8, and/or reporting you to law enforcement authorities.
      </p>
      <p>
        <b>Feedback.</b>&nbsp;If you provide Company with any feedback or
        suggestions regarding the Site (“Feedback”), you hereby assign to
        Company all rights in such Feedback and agree that Company shall have
        the right to use and fully exploit such Feedback and related information
        in any manner it deems appropriate. Company will treat any Feedback you
        provide to Company as non-confidential and non-proprietary. You agree
        that you will not submit to Company any information or ideas that you
        consider to be confidential or proprietary.
      </p>
      <p>
        <b>Indemnification.</b>&nbsp;You agree to indemnify and hold Company
        (and its officers, employees, and agents) harmless, including costs and
        attorneys’ fees, from any claim or demand made by any third party due to
        or arising out of (a) your use of the Site, (b) your violation of these
        Terms, (c) your violation of applicable laws or regulations or (d) your
        User Content. Company reserves the right, at your expense, to assume the
        exclusive defense and control of any matter for which you are required
        to indemnify us, and you agree to cooperate with our defense of these
        claims. You agree not to settle any matter without the prior written
        consent of Company. Company will use reasonable efforts to notify you of
        any such claim, action or proceeding upon becoming aware of it.
      </p>
      <PlainPageHeader>Third-Party Links &amp; Ads; Other Users</PlainPageHeader>
      <p>
        <b>Third-Party Links &amp; Ads.</b>&nbsp;The Site may contain links to
        third-party websites and services, and/or display advertisements for
        third parties (collectively,&nbsp;<b>“Third-Party Links &amp; Ads”</b>).
        Such Third-Party Links &amp; Ads are not under the control of Company,
        and Company is not responsible for any Third-Party Links &amp; Ads.
        Company provides access to these Third-Party Links &amp; Ads only as a
        convenience to you, and does not review, approve, monitor, endorse,
        warrant, or make any representations with respect to Third-Party Links
        &amp; Ads. You use all Third-Party Links &amp; Ads at your own risk, and
        should apply a suitable level of caution and discretion in doing so.
        When you click on any of the Third-Party Links &amp; Ads, the applicable
        third party’s terms and policies apply, including the third party’s
        privacy and data gathering practices. You should make whatever
        investigation you feel necessary or appropriate before proceeding with
        any transaction in connection with such Third-Party Links &amp; Ads.
      </p>
      <p>
        <b>Other Users.</b>&nbsp;Each Site user is solely responsible for any
        and all of its own User Content. Because we do not control User Content,
        you acknowledge and agree that we are not responsible for any User
        Content, whether provided by you or by others. We make no guarantees
        regarding the accuracy, currency, suitability, or quality of any User
        Content. Your interactions with other Site users are solely between you
        and such users. You agree that Company will not be responsible for any
        loss or damage incurred as the result of any such interactions. If there
        is a dispute between you and any Site user, we are under no obligation
        to become involved.
      </p>
      <p>
        <b>Release.</b>&nbsp;You hereby release and forever discharge the
        Company (and our officers, employees, agents, successors, and assigns)
        from, and hereby waive and relinquish, each and every past, present and
        future dispute, claim, controversy, demand, right, obligation,
        liability, action and cause of action of every kind and nature
        (including personal injuries, death, and property damage), that has
        arisen or arises directly or indirectly out of, or that relates directly
        or indirectly to, the Site (including any interactions with, or act or
        omission of, other Site users or any Third-Party Links &amp; Ads). IF
        YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
        SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL
        RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
        SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
        RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS
        OR HER SETTLEMENT WITH THE DEBTOR.”
      </p>
      <PlainPageHeader>Disclaimers</PlainPageHeader>
      <p>
        THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY
        (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND
        CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
        INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
        NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE
        WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED,
        TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE
        OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF
        APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL
        SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE
        DATE OF FIRST USE.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO
        THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT
        ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
        LIMITATION MAY NOT APPLY TO YOU.
      </p>
      <PlainPageHeader>Limitation on Liability</PlainPageHeader>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR
        OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS,
        LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT,
        CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES
        ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO
        USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION
        AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
        DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
      </p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE
        CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING
        FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND
        REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A
        MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE
        CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL
        HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
        AGREEMENT.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
        FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
        EXCLUSION MAY NOT APPLY TO YOU.
      </p>
      <p>
        <b>Term and Termination.</b>&nbsp;Subject to this Section, these Terms
        will remain in full force and effect while you use the Site. We may
        suspend or terminate your rights to use the Site (including your
        Account) at any time for any reason at our sole discretion, including
        for any use of the Site in violation of these Terms. Upon termination of
        your rights under these Terms, your Account and right to access and use
        the Site will terminate immediately. You understand that any termination
        of your Account may involve deletion of your User Content associated
        with your Account from our live databases. Company will not have any
        liability whatsoever to you for any termination of your rights under
        these Terms, including for termination of your Account or deletion of
        your User Content. Even after your rights under these Terms are
        terminated, the following provisions of these Terms will remain in
        effect: Sections 2.2 through 2.5, Section 3 and Sections 4 through 10.
      </p>
      <p>
        <b>Copyright Policy.</b>
      </p>
      <ul>
        <li>
          Company respects the intellectual property of others and asks that
          users of our Site do the same. In connection with our Site, we have
          adopted and implemented a policy respecting copyright law that
          provides for the removal of any infringing materials and for the
          termination, in appropriate circumstances, of users of our online Site
          who are repeat infringers of intellectual property rights, including
          copyrights. If you believe that one of our users is, through the use
          of our Site, unlawfully infringing the copyright(s) in a work, and
          wish to have the allegedly infringing material removed, the following
          information in the form of a written notification (pursuant to 17
          U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
        </li>
        <li>your physical or electronic signature;</li>
        <li>
          identification of the copyrighted work(s) that you claim to have been
          infringed;
        </li>
        <li>
          identification of the material on our services that you claim is
          infringing and that you request us to remove;
        </li>
        <li>sufficient information to permit us to locate such material;</li>
        <li>your address, telephone number, and e-mail address;</li>
        <li>
          a statement that you have a good faith belief that use of the
          objectionable material is not authorized by the copyright owner, its
          agent, or under the law; and
        </li>
        <li>
          a statement that the information in the notification is accurate, and
          under penalty of perjury, that you are either the owner of the
          copyright that has allegedly been infringed or that you are authorized
          to act on behalf of the copyright owner.
        </li>
      </ul>
      <p>
        Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation
        of material fact (falsities) in a written notification automatically
        subjects the complaining party to liability for any damages, costs and
        attorney’s fees incurred by us in connection with the written
        notification and allegation of copyright infringement.
      </p>
      <PlainPageHeader>General</PlainPageHeader>
      <p>
        <b>Changes.</b>&nbsp;These Terms are subject to occasional revision, and
        if we make any substantial changes, we may notify you by sending you an
        e-mail to the last e-mail address you provided to us (if any), and/or by
        prominently posting notice of the changes on our Site. You are
        responsible for providing us with your most current e-mail address. In
        the event that the last e-mail address that you have provided us is not
        valid, or for any reason is not capable of delivering to you the notice
        described above, our dispatch of the e-mail containing such notice will
        nonetheless constitute effective notice of the changes described in the
        notice. Any changes to these Terms will be effective upon the earlier of
        thirty (30) calendar days following our dispatch of an e-mail notice to
        you (if applicable) or thirty (30) calendar days following our posting
        of notice of the changes on our Site. These changes will be effective
        immediately for new users of our Site. Continued use of our Site
        following notice of such changes shall indicate your acknowledgement of
        such changes and agreement to be bound by the terms and conditions of
        such changes.
      </p>
      <p>
        <b>
          Dispute Resolution.{" "}
          <i>
            Please read this Arbitration Agreement carefully. It is part of your
            contract with Company and affects your rights. It contains
            procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION
            WAIVER.
          </i>
        </b>
      </p>
      .
      <p>
        <i>Applicability of Arbitration Agreement.</i> All claims and disputes
        (excluding claims for injunctive or other equitable relief as set forth
        below) in connection with the Terms or the use of any product or service
        provided by the Company that cannot be resolved informally or in small
        claims court shall be resolved by binding arbitration on an individual
        basis under the terms of this Arbitration Agreement. Unless otherwise
        agreed to, all arbitration proceedings shall be held in English. This
        Arbitration Agreement applies to you and the Company, and to any
        subsidiaries, affiliates, agents, employees, predecessors in interest,
        successors, and assigns, as well as all authorized or unauthorized users
        or beneficiaries of services or goods provided under the Terms.
      </p>
      <p>
        <i>Notice Requirement and Informal Dispute Resolution.</i> Before either
        party may seek arbitration, the party must first send to the other party
        a written Notice of Dispute (<b>“Notice”</b>) describing the nature and
        basis of the claim or dispute, and the requested relief. A Notice to the
        Company should be sent to: 440 N Wolfe Rd, Sunnyvale, California 94085.
        After the Notice is received, you and the Company may attempt to resolve
        the claim or dispute informally. If you and the Company do not resolve
        the claim or dispute within thirty (30) days after the Notice is
        received, either party may begin an arbitration proceeding. The amount
        of any settlement offer made by any party may not be disclosed to the
        arbitrator until after the arbitrator has determined the amount of the
        award, if any, to which either party is entitled.
      </p>
      <p>
        Arbitration Rules. Arbitration shall be initiated through the American
        Arbitration Association (<b>“AAA”</b>), an established alternative
        dispute resolution provider (<b>“ADR Provider”</b>) that offers
        arbitration as set forth in this section. If AAA is not available to
        arbitrate, the parties shall agree to select an alternative ADR
        Provider. The rules of the ADR Provider shall govern all aspects of the
        arbitration, including but not limited to the method of initiating
        and/or demanding arbitration, except to the extent such rules are in
        conflict with the Terms. The AAA Consumer Arbitration Rules
        (“Arbitration Rules”) governing the arbitration are available online at
        www.adr.org or by calling the AAA at 1-800-778-7879. The arbitration
        shall be conducted by a single, neutral arbitrator. Any claims or
        disputes where the total amount of the award sought is less than Ten
        Thousand U.S. Dollars (US $10,000.00) may be resolved through binding
        non-appearance-based arbitration, at the option of the party seeking
        relief. For claims or disputes where the total amount of the award
        sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right
        to a hearing will be determined by the Arbitration Rules. Any hearing
        will be held in a location within 100 miles of your residence, unless
        you reside outside of the United States, and unless the parties agree
        otherwise. If you reside outside of the U.S., the arbitrator shall give
        the parties reasonable notice of the date, time and place of any oral
        hearings. Any judgment on the award rendered by the arbitrator may be
        entered in any court of competent jurisdiction. If the arbitrator grants
        you an award that is greater than the last settlement offer that the
        Company made to you prior to the initiation of arbitration, the Company
        will pay you the greater of the award or $2,500.00. Each party shall
        bear its own costs (including attorney’s fees) and disbursements arising
        out of the arbitration and shall pay an equal share of the fees and
        costs of the ADR Provider.
      </p>
      <p>
        <i>Additional Rules for Non-Appearance Based Arbitration.</i> If
        non-appearance based arbitration is elected, the arbitration shall be
        conducted by telephone, online and/or based solely on written
        submissions; the specific manner shall be chosen by the party initiating
        the arbitration. The arbitration shall not involve any personal
        appearance by the parties or witnesses unless otherwise agreed by the
        parties.
      </p>
      <p>
        <i>Time Limits.</i> If you or the Company pursue arbitration, the
        arbitration action must be initiated and/or demanded within the statute
        of limitations (i.e., the legal deadline for filing a claim) and within
        any deadline imposed under the AAA Rules for the pertinent claim.
      </p>
      <p>
        <i>Authority of Arbitrator.</i> If arbitration is initiated, the
        arbitrator will decide the rights and liabilities, if any, of you and
        the Company, and the dispute will not be consolidated with any other
        matters or joined with any other cases or parties. The arbitrator shall
        have the authority to grant motions dispositive of all or part of any
        claim. The arbitrator shall have the authority to award monetary
        damages, and to grant any non-monetary remedy or relief available to an
        individual under applicable law, the AAA Rules, and the Terms. The
        arbitrator shall issue a written award and statement of decision
        describing the essential findings and conclusions on which the award is
        based, including the calculation of any damages awarded. The arbitrator
        has the same authority to award relief on an individual basis that a
        judge in a court of law would have. The award of the arbitrator is final
        and binding upon you and the Company.
      </p>
      <p>
        <i>Waiver of Jury Trial.</i> THE PARTIES HEREBY WAIVE THEIR
        CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
        FRONT OF A JUDGE OR A JURY, instead electing that all claims and
        disputes shall be resolved by arbitration under this Arbitration
        Agreement. Arbitration procedures are typically more limited, more
        efficient and less costly than rules applicable in a court and are
        subject to very limited review by a court. In the event any litigation
        should arise between you and the Company in any state or federal court
        in a suit to vacate or enforce an arbitration award or otherwise, YOU
        AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that
        the dispute be resolved by a judge.
      </p>
      <p>
        <i>Waiver of Class or Consolidated Actions.</i> ALL CLAIMS AND DISPUTES
        WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR
        LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF
        MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY
        OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
      </p>
      <p>
        <i>Confidentiality.</i> All aspects of the arbitration proceeding,
        including but not limited to the award of the arbitrator and compliance
        therewith, shall be strictly confidential. The parties agree to maintain
        confidentiality unless otherwise required by law. This paragraph shall
        not prevent a party from submitting to a court of law any information
        necessary to enforce this Agreement, to enforce an arbitration award, or
        to seek injunctive or equitable relief.
      </p>
      <p>
        <i>Severability.</i> If any part or parts of this Arbitration Agreement
        are found under the law to be invalid or unenforceable by a court of
        competent jurisdiction, then such specific part or parts shall be of no
        force and effect and shall be severed and the remainder of the Agreement
        shall continue in full force and effect.
      </p>
      <p>
        <i>Right to Waive.</i> Any or all of the rights and limitations set
        forth in this Arbitration Agreement may be waived by the party against
        whom the claim is asserted. Such waiver shall not waive or affect any
        other portion of this Arbitration Agreement.
      </p>
      <p>
        <i>Survival of Agreement.</i> This Arbitration Agreement will survive
        the termination of your relationship with Company.
      </p>
      <p>
        <i>Small Claims Court.</i> Notwithstanding the foregoing, either you or
        the Company may bring an individual action in small claims court.
      </p>
      <p>
        <i>Emergency Equitable Relief.</i> Notwithstanding the foregoing, either
        party may seek emergency equitable relief before a state or federal
        court in order to maintain the status quo pending arbitration. A request
        for interim measures shall not be deemed a waiver of any other rights or
        obligations under this Arbitration Agreement.
      </p>
      <p>
        <i>Claims Not Subject to Arbitration.</i> Notwithstanding the foregoing,
        claims of defamation, violation of the Computer Fraud and Abuse Act, and
        infringement or misappropriation of the other party’s patent, copyright,
        trademark or trade secrets shall not be subject to this Arbitration
        Agreement.
      </p>
      <p>
        <i>Courts.</i> In any circumstances where the foregoing Arbitration
        Agreement permits the parties to litigate in court, the parties hereby
        agree to submit to the personal jurisdiction of the courts located
        within Santa Clara County, California, for such purpose
      </p>
      <p>
        <b>Export.</b>&nbsp;The Site may be subject to U.S. export control laws
        and may be subject to export or import regulations in other countries.
        You agree not to export, reexport, or transfer, directly or indirectly,
        any U.S. technical data acquired from Company, or any products utilizing
        such data, in violation of the United States export laws or regulations.
      </p>
      <p>
        <b>Disclosures.</b>&nbsp;Company is located at the address in Section
        10.8. If you are a California resident, you may report complaints to the
        Complaint Assistance Unit of the Division of Consumer Product of the
        California Department of Consumer Affairs by contacting them in writing
        at 400 R Street, Sacramento, CA 95814, or by telephone at (800)
        952-5210.
      </p>
      <p>
        <b>Electronic Communications.</b>&nbsp;The communications between you
        and Company use electronic means, whether you use the Site or send us
        emails, or whether Company posts notices on the Site or communicates
        with you via email. For contractual purposes, you (a) consent to receive
        communications from Company in an electronic form; and (b) agree that
        all terms and conditions, agreements, notices, disclosures, and other
        communications that Company provides to you electronically satisfy any
        legal requirement that such communications would satisfy if it were be
        in a hardcopy writing. The foregoing does not affect your non-waivable
        rights.
      </p>
      <p>
        <b>Entire Terms.</b>&nbsp;These Terms constitute the entire agreement
        between you and us regarding the use of the Site. Our failure to
        exercise or enforce any right or provision of these Terms shall not
        operate as a waiver of such right or provision. The section titles in
        these Terms are for convenience only and have no legal or contractual
        effect. The word “including” means “including without limitation”. If
        any provision of these Terms is, for any reason, held to be invalid or
        unenforceable, the other provisions of these Terms will be unimpaired
        and the invalid or unenforceable provision will be deemed modified so
        that it is valid and enforceable to the maximum extent permitted by law.
        Your relationship to Company is that of an independent contractor, and
        neither party is an agent or partner of the other. These Terms, and your
        rights and obligations herein, may not be assigned, subcontracted,
        delegated, or otherwise transferred by you without Company’s prior
        written consent, and any attempted assignment, subcontract, delegation,
        or transfer in violation of the foregoing will be null and void. Company
        may freely assign these Terms. The terms and conditions set forth in
        these Terms shall be binding upon assignees.
      </p>
      <p>
        <b>Copyright/Trademark Information.</b>&nbsp;Copyright © 2017 Buddy AI
        Inc. All rights reserved. All trademarks, logos and service marks
        (“Marks”) displayed on the Site are our property or the property of
        other third parties. You are not permitted to use these Marks without
        our prior written consent or the consent of such third party which may
        own the Marks.
      </p>
      <b>Contact Information:</b>
      <p>
        Percept.AI
        <br />
        Address:
        <br />
        440 N Wolfe Rd
        <br />
        Sunnyvale, California 94085
        <br />
        Email: admin@percept.ai
        <br />
      </p>
    </Container>
  </Layout>
);

export default TermsOfUse;
